import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import './homescreenn.css'; // Stil dosyasını içe aktarın
import { 

  MdAccountCircle, 
  MdAnnouncement, // Duyuru İkonu
  MdGroup, // Çoklu Kullanıcı İkonu
  MdReport, // Rapor İkonu
  MdPerson, // Kişisel Gelişim İkonu
  MdExitToApp, // Çıkış Yapma İkonu
  MdAssignment,
  MdHome, // Home icon
  MdCampaign,
  MdNotifications,
  MdTrendingUp, MdGroupWork, MdShowChart


} from 'react-icons/md';

const firebaseConfig = {
  apiKey: "AIzaSyDdugV4yuPfYBfVdTs6xEEFhrB6H9XgvRA",
  authDomain: "dernek-9b26c.firebaseapp.com",
  databaseURL: "https://dernek-9b26c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dernek-9b26c",
  storageBucket: "dernek-9b26c.appspot.com",
  messagingSenderId: "58054737120",
  appId: "1:58054737120:web:c4535a79d85d6a63102485",
  measurementId: "G-N3BL5L2NZN"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const HomeScreen = () => {
  const navigate = useNavigate();
  const [hasIkApproval, setHasIkApproval] = useState(false); // Kullanıcının IK onayını kontrol etmek için state
  const [unapprovedCount, setUnapprovedCount] = useState(0);
  const [additionalDataLoaded, setAdditionalDataLoaded] = useState(false);
  const [unapprovedCount2, setUnapprovedCount2] = useState(0);
  const [userDetailsLoaded, setUserDetailsLoaded] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [noDataCount, setNoDataCount] = useState(0); // Boş tarih sayısı
const [allDataComplete, setAllDataComplete] = useState(false); // Tüm tarihler dolu mu

    // Kullanıcının IK bilgisini kontrol etmek için state
    const [hasIkPermission, setHasIkPermission] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };
  useEffect(() => {
    const db = getDatabase();
    const userId = localStorage.getItem("userId");
    if (userId) {
        const userRef = ref(db, 'gorevler/' + userId);
        onValue(userRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                const allDates = Object.keys(data).sort((a, b) => a.localeCompare(b));
                const missionsWithCompleteDates = calculateCompleteDateRange(allDates, data);
                const noDataCount = missionsWithCompleteDates.filter(mission => !Object.keys(mission.data).length).length;
                setNoDataCount(noDataCount);
                setAllDataComplete(noDataCount === 0);
            } else {
                const currentDate = new Date();
                const pastWeekDates = getPastWeekDates(currentDate);
                const emptyMissions = pastWeekDates.map(date => ({
                    date: formatDate(date),
                    data: {}
                }));
                setNoDataCount(emptyMissions.length);
                setAllDataComplete(false);
            }
        });
    }
}, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = isMobile ? mobileStyles : desktopStyles;

    // Resize event listener for handling screen size changes

    /*
    useEffect(() => {
      const unsubscribe = messaging().onMessage(async remoteMessage => {
        console.log('A new FCM message arrived!', JSON.stringify(remoteMessage));
      });
    
      return unsubscribe;
    }, []);
    
    */
// Uygulama yüklendiğinde çalışacak fonksiyon
useEffect(() => {
  // Kullanıcı bilgilerini yükleyen asenkron fonksiyon
  const loadUserDetails = async () => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      // Kullanıcı ID'sini global değişkene ata
      global.userDetails = { userId: userId }; // global nesne kullanımını minimize etmek iyi bir pratiktir
      //requestUserPermission();
      await loadAdditionalUserData(userId);
    } else {
      // Kullanıcı ID yoksa, Login ekranına yönlendir
      //navigate('/login', { replace: true });

    }
  };


  // Kullanıcıya özgü verileri yükleyen fonksiyon
  const loadAdditionalUserData = async (userId) => {
      const db = getDatabase();
      const userRef = ref(db, `kullanicilar/${userId}`); // "kullanicilar" yerine "users" kullanıldı varsayım olarak
      
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData && (userData.ik || userData.IKyonetim)) {
          setHasIkPermission(true);
          console.log("IK izni var: " + userId);
        } else {
          setHasIkPermission(false);
          console.log("IK izni yok: " + userId);
        }
      }, {
        onlyOnce: true
      });
    };

    loadUserDetails();
  }, [navigate]); // veya [navigate] eğer React Router v6 kullanıyorsanız

  const handleLogout = async () => {
    try {
      // Firebase oturumunu sonlandır
      await auth.signOut();
  
      // Yerel depolama alanındaki bilgileri temizle
      localStorage.removeItem('userId');
      
      
  
      // Kullanıcıyı login sayfasına yönlendir
      navigate('/login', { replace: true }); 
    } catch (error) {
      console.error('Çıkış yapılırken bir hata oluştu:', error);
    }
  };
  


  // Çıkış işle
  const logout = async () => {
    try {
      await localStorage.removeItem('userId');
      global.userDetails.userId = null;
      navigate('/login', { replace: true });

    } catch (error) {
      console.error("Çıkış yapılırken hata oluştu: ", error);
    }
  };
  

  useEffect(() => {
    const getUserId = async () => {
      try {
        const userId = await localStorage.getItem('userId');
        if (userId !== null) {
         
          console.log("Kullanıcı ID: " + userId);
          checkIkApproval(userId); // Kullanıcı ID'si alındıktan sonra IK onayını kontrol et
        } else {
          // Kullanıcı ID'si bulunamazsa, uygulama giriş ekranına yönlendirilmeli
          //navigate('/login', { replace: true });
        }
      } catch (error) {
        console.error("Kullanıcı ID alınırken hata oluştu: ", error);
       // navigate('/login', { replace: true });
      }
    };
  
    const checkIkApproval = (userId) => {
      if (!userId) {
        console.error("Kullanıcı ID'si bulunamadı");
        return;
      }
  
      const db = getDatabase();
      const userRef = ref(db, `kullanicilar/${userId}`);
  
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData && userData.ik === true) {
          setHasIkApproval(true);
        } else {
          setHasIkApproval(false);
        }
      }, {
        onlyOnce: true
      });
    };
  
    getUserId();
  }, [navigate]); // Bağımlılık olarak sadece navigation eklenmiştir
  

  const handleMissionSystemNavigation = () => {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      console.error("Kullanıcı ID'si bulunamadı");
      return;
    }
  
    const db = getDatabase();
    const userRef = ref(db, `kullanicilar/${userId}`);
  
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      if (userData && userData.ik === true) {
        // Eğer kullanıcının 'ik' değeri true ise MissionSystem3 ekranına yönlendir
        navigate('/missionsystem3');
      } else {
        // 'ik' değeri false veya tanımlı değilse MissionSystemScreen2 ekranına yönlendir
        navigate('/missionsystem2');
      }
    }, {
      onlyOnce: true // Sadece bir kere veriyi almak için
    });
  };
  

  


 
   // İlk aşama: Kullanıcı bilgilerini yükle
   useEffect(() => {
    const db = getDatabase();
    const userId = localStorage.getItem("userId");

    if (userId) {
      const userRef = ref(db, 'kullanicilar/' + userId);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setUserInfo(data);
          setUserDetailsLoaded(true);
        }
      }, { onlyOnce: true });
    }
  }, []); // Bağımlılık olarak sadece ilk render'da çalışır

  // İkinci aşama: Ekstra verileri yükle
  useEffect(() => {
    if (userDetailsLoaded && userInfo) {
      const db = getDatabase();
      const usersRef = ref(db, 'kullanicilar');
      onValue(usersRef, (snapshot) => {
        const usersData = snapshot.val();
        let unapprovedUsers = [];
        if (usersData) {
          Object.keys(usersData).forEach(key => {
            const user = usersData[key];
            if (userInfo.IKyonetim) {
              if (user.onay === true && user.IKstatus === "belirlenmedi") {
                unapprovedUsers.push(key);
              }
            } else {
              if (user.otherJob === userInfo.otherJob && user.onay === false && user.status === "belirlenmedi") {
                unapprovedUsers.push(key);
              }
            }
          });
          setUnapprovedCount(unapprovedUsers.length);
          setAdditionalDataLoaded(true);
        } else {
          setUnapprovedCount(0);
        }
      });
    }
  }, [userDetailsLoaded, userInfo]); // Bağımlılıklar: userDetailsLoaded ve userInfo

  // Üçüncü aşama: Global kullanıcı verilerini ve görev değişikliklerini yükle
  useEffect(() => {
    if (additionalDataLoaded && userInfo) {
      const db = getDatabase();
      const userId = global.userDetails?.userId;
      const globalUserRef = ref(db, `kullanicilar/${userId}`);

      onValue(globalUserRef, (snapshot) => {
        const globalUserData = snapshot.val();
        if (globalUserData) {
          const usersRef = ref(db, 'gorevdegisikligi');
          onValue(usersRef, (snapshot) => {
            const data = snapshot.val();
            let filteredKeys = [];
            if (data) {
              Object.keys(data).forEach(key => {
                const task = data[key];
                if (globalUserData.IKyonetim && task.onay === true && task.IKDurum === 'belirlenmedi') {
                  filteredKeys.push(key);
                } else if (task.yeniKomisyon === globalUserData.otherJob && task.onay === false && task.Durum === 'belirlenmedi') {
                  filteredKeys.push(key);
                }
              });
              setUnapprovedCount2(filteredKeys.length);
            } else {
              setUnapprovedCount2(0);
            }
          });
        }
      });
    }
  }, [additionalDataLoaded, userInfo]); // Bağımlılıklar: additionalDataLoaded ve userInfo

  
  const calculateCompleteDateRange = (allDates, data) => {
    let dateArray = [];
    let startDate = new Date(allDates[0].split('-').reverse().join('-'));
    let currentDate = new Date();
  
    while (startDate <= currentDate) {
      let formattedDate = formatDate(startDate);
      dateArray.push({
        date: formattedDate,
        data: data[formattedDate] || {}
      });
      startDate.setDate(startDate.getDate() + 1);
    }
    return dateArray;
  };
  
  const formatDate = (date) => {
    return [
      String(date.getDate()).padStart(2, '0'),
      String(date.getMonth() + 1).padStart(2, '0'),
      date.getFullYear(),
    ].join('-');
  };
  
  const getPastWeekDates = (currentDate) => {
    let dates = [];
    for (let i = 0; i < 7; i++) {
        const date = new Date(currentDate);
        date.setDate(currentDate.getDate() - i);
        dates.push(date);
    }
    return dates;
};


 // Rapor sistemi butonuna tıklandığında çalışacak fonksiyon
 const handleReportSystemNavigation = () => {
 
  if (hasIkPermission) {
    // IK izinleri varsa, kullanıcıyı InsanKaynaklari ekranına yönlendir
    navigate('/report');
  } else {
    // İzin yoksa, uyarı mesajı göster
    window.alert("Erişim Engellendi", "Bu bölüme erişim için gerekli yetkiniz yok.");
  }
};



const [activeTab, setActiveTab] = useState('home');
//-------------
const handleTabClick = (tabName) => {
  setActiveTab(tabName);
  switch (tabName) {
    case 'home':
      navigate('/home');
      break;
    case 'announcement':
      navigate('/seeduyuru');
      break;
    case 'profile':
      navigate('/profile');
      break;
    // Diğer sekmeler için case blokları ekleyebilirsiniz
    default:
      break;
  }
};
/*
async function requestUserPermission() {
  const authStatus = await messaging().requestPermission();
    authStatus === messaging.AuthorizationStatus.AUTHORIZED || 
    authStatus === messaging.AuthorizationStatus.PROVISIONAL;
  // Bildirim izni durumuna bakmaksızın token'ı almaya çalış
  console.log('Bildirim İzni :', authStatus);
  getToken();
}

async function getToken() {
  const fcmToken = await messaging().getToken();
  if (fcmToken) {
    console.log('Your Firebase Token is:', fcmToken);
    await saveTokenToDatabase(fcmToken);
  } else {
    console.log('Failed to get the token for the device');
  }
}

// Uygulama başlatıldığında çağır

async function saveTokenToDatabase(token) {
  try {
    // Global userId'yi al
    const userId = global.userDetails.userId;

    // Kullanıcı için Firebase veritabanında bir referans oluştur
    const userRef = ref(database, `/kullanicilar/${userId}`);

    // Tokenı veritabanına kaydet
    await update(userRef, { push_token: token });
    console.log('Veri tabanına kayıt edildi.');
  } catch (error) {
    console.error('Veri tabanına kaydetme sırasında hata oluştu:', error);
  }
}


useEffect(() => {
  // Global kullanıcı ID'sini al
  const userId = global.userDetails.userId;
  
  if (userId) {
    checkDailyTask(userId);
  }
}, []);

const checkDailyTask = async (userId) => {
  const formattedDate = new Date();
const currentDate = `${formattedDate.getDate().toString().padStart(2, '0')}-${(formattedDate.getMonth() + 1).toString().padStart(2, '0')}-${formattedDate.getFullYear()}`;
console.log("Veri tabanıyla eşleştireceğim bugünün tarihi: " + currentDate);

  const db = getDatabase();
  const taskRef = ref(db, `gorevler/${userId}`);

  onValue(taskRef, (snapshot) => {
    const tasks = snapshot.val();
    if (tasks && !tasks[currentDate]) {
      // Eğer belirtilen tarihte görev yoksa bildirim gönder
      sendNotification(userId);
    }
  });
};



const sendNotification = async (userId) => {

  const db = getDatabase();
  const userRef = ref(db, `kullanicilar/${userId}`);

  onValue(userRef, async (snapshot) => {
    const userData = snapshot.val();
    if (userData && userData.push_token) {
      const notificationMessage = {
        to: userData.push_token, // Kullanıcının FCM token'ı
        notification: {
          title: 'Günlük Görev Hatırlatması',
          body: 'Bugün için herhangi bir göreviniz bulunmamaktadır.'
        }
      };

      try {
        const response = await fetch('https://fcm.googleapis.com/fcm/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'key=AAAADYRU_OA:APA91bHn2ADktYUNLKA1XKSumJyyBH9BUDmGwileDy07y08ScIa0KmTPInUdtAlCCrIs8NK9gMmRl8poNJfp4uiA42p5oFJSwyYlzfRb-Wf4ZxhbFG-399VUkGRZ9opZbspTAZwcFFL7' // Firebase Cloud Messaging server key
          },
          body: JSON.stringify(notificationMessage)
        });

        const responseData = await response.json();
        console.log('Bildirim gönderildi:', responseData);
      } catch (error) {
        console.error('Bildirim gönderirken hata:', error);
      }
    }
  }, {
    onlyOnce: true
  });
};


//--------


*/
  return (
    <div style={styles.homeContainer}>
   {/* Çıkış butonu */}
   <button onClick={handleLogout} style={styles.logoutButton}>

        <MdExitToApp name="exit-to-app" size={50} color="#000" /> 
      </button>


     <div style={styles.homeContainer2}>
    
 <img 
        src={require('../assets/imar.png')} // 'path/to/your/assets/image.png' kısmını resminizin konumu ile değiştirin
        style={styles.homeimageStyle} // İsteğe bağlı olarak resmin stilini belirleyin
      />
        <div style={styles.divider}/>

     
        <button style={styles.reportButton4} onClick={() => navigate('/personaldevelopment')}>
          <div style={styles.ButtonHomeScreenContainer}>
            <img src={require('../assets/ust.png')} alt="Personal Development" style={styles.iconStyle2} />
            <p style={styles.homeMenuText}>KİŞİSEL GELİŞİM SİSTEMİ</p>
            {allDataComplete ? (
              <span style={styles.checkMark}>✔</span>
            ) : (
              noDataCount > 0 && (
                <span style={styles.badge}>{noDataCount}</span>
              )
            )}
          </div>
        </button>

   



        <button style={styles.reportButton4} onClick={handleMissionSystemNavigation}>
          <div style={styles.ButtonHomeScreenContainer}>
            <img src={require('../assets/orta.png')} alt="Mission System" style={styles.iconStyle2} />
            <p style={styles.homeMenuText}>GÖREV YÖNETİM SİSTEMİ</p>
            {hasIkApproval && (unapprovedCount + unapprovedCount2 > 0) && (
              <div style={styles.taskManagementBadge2}>
                <p style={styles.taskManagementBadgeText}>{unapprovedCount + unapprovedCount2} </p>
              </div>
            )}
          </div>
        </button>


        {hasIkPermission && (
          <button style={styles.reportButton4} onClick={handleReportSystemNavigation}>
            <div style={styles.ButtonHomeScreenContainer}>
              <img src={require('../assets/alt.png')} alt="Report System" style={styles.iconStyle2} />
              <p style={styles.homeMenuText}>RAPOR VE BİLGİ SİSTEMİ</p>
            </div>
          </button>
        )}
           {hasIkPermission && (
        <button style={styles.reportButton4} onClick={() => navigate('/komisyonmanager')}>
  <div style={styles.ButtonHomeScreenContainer2}>
    <MdAssignment style={styles.iconStyle} /> {/* React Icons'dan bir ikon */}
    <p style={styles.homeMenuText2}>GÖREVLERİ YÖNET</p>
  </div>
</button>
)}

       <button style={styles.reportButton4} onClick={() => navigate('/guidelines')}>
  <div style={styles.ButtonHomeScreenContainer2}>
    <MdAssignment style={styles.iconStyle} /> {/* React Icons'dan bir ikon */}
    <p style={styles.homeMenuText2}>YÖNERGELERİMİZ</p>
  </div>
</button>


    
</div>

      {/* Alt menü için bir başka View bileşeni */}
      


      
    </div>
  );
};
// Stil dosyalarınızın olması gerekiyor, örnek olarak styles tanımlamasını ekleyelim



const desktopStyles = {
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '100vh', // Sayfanın tam yüksekliğini kaplar
    width: '100%', // Tam genişliği kaplar
    backgroundImage: `url(${require('../assets/background.jpg')})`, /* Resminizin yolunu buraya yazın */
    backgroundSize: 'cover', /* Resmin ekranı kaplamasını sağlar */
    backgroundRepeat: 'no-repeat', /* Resmin tekrar etmesini engeller */
    backgroundPosition: 'center center', /* Resmi ortalar */
    color: 'black',
    fontFamily: '"Arial", sans-serif',
  },
  iconStyle: {
    fontSize: '90px', // İkonun boyutu
    marginRight: '10px', // İkon ve metin arasındaki boşluk
    color: 'black', // İkon rengi
  },
  
  badge: {
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    marginBottom: '20px',
    fontSize: '12px',
    fontSize: 18
},
checkMark: {
    backgroundColor: 'green',
    color: 'white',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    fontSize: '17px',
},
  logoutButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: '10%',
    height: '10%',
    fontSize: 18,
    padding: 10,
    border: 'none',
    borderRadius: 5,
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },
  homeContainer2: {
    width: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
  },
  homeimageStyle: {
    width: 150,
    height: 150,
    margin: '20px auto',
  },
  divider: {
    width: '80%',
    height: 2,
    backgroundColor: '#ccc',
    margin: '20px 0',
  },
  reportButton4: {
    background: 'linear-gradient(to right, #fff, #fff)',
    color: 'black',
    padding: '15px',
    width: '100%',
    margin: '10px 0',
    display: 'flex',
    marginTop: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '20px',
    
    position: 'relative',
    boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
    border: 'none', // Çizgiyi kaldırmak için eklenen özellik
    outline: 'none', // Çizgiyi kaldırmak için eklenen özellik
  },
  
 
  iconStyle2: {
    marginRight: 0,
    fontSize: 10,
    width: 100, // Set the desired width
    height: 100, // Set the desired height
    color: 'black',
  },
  homeMenuText: {
    fontSize: '20px',
    marginLeft: 10,
    fontWeight: 'bold',
  },
  homeMenuText2: {
    fontSize: '20px',
    textAlign: 'center', // Metni ortalar
    fontWeight: 'bold',
  },

  taskManagementBadgeText: {
    
    lineHeight: '1',
    textAlign: 'center',
    fontSize: 14,
  },
  ButtonHomeScreenContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  
  ButtonHomeScreenContainer2: {
    display: 'flex',
    justifyContent: 'flex-start', // İkon ve metni hizalı tutar
    alignItems: 'center',        // Dikey hizalama
    textAlign: 'left',           // Metin hizalaması
    padding: '10px',
  },
  

  
  taskManagementBadge2: {
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    marginBottom: '20px',
    fontSize: '12px',
    fontSize: 18
  },
  taskManagementBadgeText: {
    fontWeight: 'bold',
    lineHeight: '1',
    textAlign: 'center',
    fontSize: 20,
  },
  homeBottomMenu: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: 'gray',
    color: 'white',
    padding: '10px 0',
    height: '80px',
    borderRadius: '60px 60px 0 0',
    boxShadow: '0 -6px 20px rgba(0,0,0,0.5)',
  },
  homeIconItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 18,
    cursor: 'pointer',
    margin: '0 10px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    padding: '10px',
    textAlign: 'center',
    alignItems: 'center',
    width: '80px',
    height: '80px',
    transition: 'background-color 0.3s ease',
  },
  homeIconItemAnnouncement: {
    backgroundColor: 'white', // Açık turuncu
  },
  homeIconItemProfile: {
    backgroundColor: 'white', // Açık mavi
  },
  homeIconItemActive: {
    backgroundColor: 'white',
    color: 'black',
  },
  homeIconText: {
    marginTop: 5,
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold',
  },
  homeIconCenter: {
    position: 'absolute',
    top: -50, // Home button will be slightly above
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    width: '120px',
    height: '120px'
  },
};

const mobileStyles = {
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '100vh', // Sayfanın tam yüksekliğini kaplar
    width: '100%', // Tam genişliği kaplar
    backgroundImage: `url(${require('../assets/background.jpg')})`, /* Resminizin yolunu buraya yazın */
    backgroundSize: 'cover', /* Resmin ekranı kaplamasını sağlar */
    backgroundRepeat: 'no-repeat', /* Resmin tekrar etmesini engeller */
    backgroundPosition: 'center center', /* Resmi ortalar */
    color: 'black',
    fontFamily: '"Arial", sans-serif',
  },
  iconStyle: {
    fontSize: '24px', // İkonun boyutu
    marginRight: '10px', // İkon ve metin arasındaki boşluk
    color: 'black', // İkon rengi
  },
  
  badge: {
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    marginBottom: '20px',
    fontSize: '12px',
    fontSize: 18
},
homeMenuText2: {
  fontSize: '20px',
  marginLeft: 10,
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
},

checkMark: {
  backgroundColor: 'green',
  color: 'white',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  fontSize: '17px',
},
  logoutButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: '15%',
    height: '15%',
    fontSize: 14,
    padding: 5,
    border: 'none',
    borderRadius: 5,
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },
  homeContainer2: {
    width: '100%',
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
  },
  homeimageStyle: {
    width: 130,
    height: 130,
    margin: '10px auto',
  },
  divider: {
    width: '80%',
    height: 2,
    backgroundColor: '#bbb',
    margin: '10px 0',
  },
  reportButton4: {
    background: 'linear-gradient(to right, #fff, #fff)',
    color: 'black',
    padding: '15px',
    width: '100%',
    margin: '10px 0',
    display: 'flex',
    marginTop: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '20px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
    position: 'relative',
    border: 'none', // Çizgiyi kaldırmak için eklenen özellik
    outline: 'none', // Çizgiyi kaldırmak için eklenen özellik
  },
  

  iconStyle2: {
    marginRight: 10,
    fontSize: 10,
    width: 100, // Set the desired width
    height: 100, // Set the desired height
    color: 'black',
  },
 
  taskManagementBadge2: {
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    marginBottom: '20px',
    fontSize: '12px',
    fontSize: 18
  },
  taskManagementBadgeText: {
    fontWeight: 'bold',
    lineHeight: '1',
    textAlign: 'center',
    fontSize: 15,
  },

  ButtonHomeScreenContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
 
  iconStyle2: {
    marginRight: 0,
    fontSize: 10,
    width: 80, // Set the desired width
    height: 80, // Set the desired height
    color: 'black',
  },

  taskManagementBadgeText: {
    fontWeight: 'bold',
    lineHeight: '1',
    textAlign: 'center',
    fontSize: 20,
  },
  ButtonHomeScreenContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  
  homeMenuText: {
    fontSize: 16,
    fontWeight: 'bold',
    alignItems: 'center',
    arginLeft: 10,
    justifyContent: 'center',
  
  },
 
  taskManagementBadgeText: {
    fontWeight: 'bold',
    lineHeight: '1',
    textAlign: 'center',
    fontSize: 20,
  },
  homeBottomMenu: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: 'gray',
    color: 'white',
    padding: '10px 0',
    height: '80px',
    borderRadius: '60px 60px 0 0',
    boxShadow: '0 -6px 20px rgba(0,0,0,0.5)',
  },
  homeIconItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 18,
    cursor: 'pointer',
    margin: '0 10px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    padding: '10px',
    textAlign: 'center',
    alignItems: 'center',
    width: '70px',
    height: '70px',
    transition: 'background-color 0.3s ease',
  },
  homeIconItemAnnouncement: {
    backgroundColor: 'white', // Açık turuncu
  },
  homeIconItemProfile: {
    backgroundColor: 'white', // Açık mavi
  },
  homeIconItemActive: {
    backgroundColor: 'white',
    color: 'black',
  },
  homeIconText: {
    marginTop: 5,
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold',
  },
  homeIconCenter: {
    position: 'absolute',
    top: -20, // Home button will be slightly above
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    width: '80px',
    height: '80px'
  },
};


export default HomeScreen;
