import React, { useState, useEffect } from 'react';
import { useNavigate , useParams, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';

import Datetime from 'react-datetime';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue, get, set, update} from 'firebase/database';
import moment from 'moment';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker'; // Web için tarih seçici
import { getAnalytics } from 'firebase/analytics';
import {
    MdVisibility, // Göz ikonu, genel görüntüleme için kullanılabilir
    MdEdit, // Düzeltme kalem ikonu, her türlü düzenleme için
    MdSchool, // Akademik hedef ve çalışmalar için okul ikonu
    MdFamilyRestroom, // Aile ile etkinlikler için aile ikonu
    MdPalette, // Sanatsal uğraşlar için palet ikonu
    MdAccessTime, // Zamanla ilgili görevler için saat ikonu
    MdSportsSoccer, // Spor aktiviteleri için futbol topu ikonu
    MdDateRange,
    MdPeople, // Üye sayısı veya toplantı katılımcıları için insanlar ikonu
    MdMonetizationOn, // Mali işler için para ikonu
    MdEvent, // Planlanmış etkinlikler için etkinlik ikonu
    MdStar,
    MdSave,
    MdMenuBook
} from 'react-icons/md';







const DayMissionScreenEdit = () => {  
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const userId = localStorage.getItem("userId");
    const location = useLocation(); // useLocation hook'unu kullanarak geçiş yaptığınız state'e erişim
    const [baslangicDate, setBaslangicDate] = useState(new Date());
    const [bitisDate, setBitisDate] = useState(new Date());
    const [isVisible, setIsVisible] = useState(false);

    const [currentDate, setCurrentDate] = useState('');
    const [day, setDay] = useState('');
    const [spiritualPoints, setSpiritualPoints] = useState('');
    const [academicGoal, setAcademicGoal] = useState('');
    const [academicGoalDate, setAcademicGoalDate] = useState(null);
    const [academicWorkDuration, setAcademicWorkDuration] = useState('');
    const [familyTime, setFamilyTime] = useState('Yapılmadı');
  
    const [artArea, setArtArea] = useState('');
    const [artDuration, setArtDuration] = useState('');
    const [sportTime, setSportTime] = useState('Yapılmadı');
    const [committeeMemberCount, setCommitteeMemberCount] = useState('');
    const [meetingAttendance, setMeetingAttendance] = useState('');
    const [financialContribution, setFinancialContribution] = useState('');
    const [activityParticipation, setActivityParticipation] = useState('');
    const [nextActivityPlan, setNextActivityPlan] = useState('');

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const styles = isMobile ? mobileStyles : desktopStyles;
    const styles2 = isMobile ? mobileStyles2 : desktopStyles2;

    
    useEffect(() => {
      if (location.state) {
          const {
              currentDate = '',
              missionData = {}
          } = location.state;

          setCurrentDate(currentDate);
          setDay(missionData.dayName || '');
          setSpiritualPoints(missionData.spiritualPoints || '');
          setAcademicGoal(missionData.academicGoal || '');
          setAcademicGoalDate(missionData.academicGoalDate ? moment(missionData.academicGoalDate, 'DD/MM/YYYY').toDate() : null);
          setAcademicWorkDuration(missionData.academicWorkDuration || '');
          setFamilyTime(missionData.familyTime || 'Yapılmadı');
          setArtArea(missionData.artArea || '');
          setArtDuration(missionData.artDuration || '');
          setSportTime(missionData.sportTime || 'Yapılmadı');
          setCommitteeMemberCount(missionData.committeeMemberCount || '');
          setMeetingAttendance(missionData.meetingAttendance || '');
          setFinancialContribution(missionData.financialContribution || '');
          setActivityParticipation(missionData.activityParticipation || '');
          setNextActivityPlan(missionData.nextActivityPlan || '');
      } else {
          console.log('Gelen veriler boş.');
      }
  }, [location.state]);



    const [selectedDate, setSelectedDate] = useState(null);
    const firebaseConfig = {
      apiKey: "AIzaSyDdugV4yuPfYBfVdTs6xEEFhrB6H9XgvRA",
      authDomain: "dernek-9b26c.firebaseapp.com",
      databaseURL: "https://dernek-9b26c-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "dernek-9b26c",
      storageBucket: "dernek-9b26c.appspot.com",
      messagingSenderId: "58054737120",
      appId: "1:58054737120:web:c4535a79d85d6a63102485",
      measurementId: "G-N3BL5L2NZN"
    };
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    useEffect(() => {
      
      const userId = localStorage.getItem("userId");
      if (!userId) {
        console.error("Kullanıcı kimliği bulunamadı!");
        // Burada uygun bir hata işlemi yapabilirsiniz, örneğin kullanıcıyı bir giriş sayfasına yönlendirebilirsiniz.
        return;
    }
      if (userId) {
        const db = getDatabase(app);
        const userRef = ref(db, `kullanicilar/${userId}`);
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          setUserData(data);
        }, {
          onlyOnce: true
        });
      }
    }, []);
    
    
  
  
  
    // State değişkenleri
    
   // İkon boyutu ve renk tanımlamaları
  
  
  
  
   const iconSize = 30;
   const iconColor = 'black';
    // Stil tanımlamaları
   
  
    const toggleFamilyTime = () => {
      setFamilyTime(familyTime === 'Yapıldı' ? 'Yapılmadı' : 'Yapıldı');
    };
  
  
    const toggleSportTime = () => {
      // Benzer şekilde spor zamanı için toggle işlemi
      setSportTime(sportTime === 'Yapıldı' ? 'Yapılmadı' : 'Yapıldı');
    };
  
    const handleSubmit = async () => {

      if (!userData) {
          console.error('Kullanıcı bilgileri yüklenmedi.');
          return;
      }
  
      const { fullName, otherJob, selectedCityName, selectedDistrictName } = userData;
      if (!fullName || !otherJob || !selectedCityName || !selectedDistrictName) {
          console.error('Kullanıcı bilgileri eksik.');
          return;
      }
  
      
  
      const dbRef = ref(getDatabase(), `gorevler/${userId}/${currentDate}`);
      const updatedMissionData = {
          currentDate: currentDate,
          dayName: day,
          fullName: fullName,
          otherJob: otherJob,
          selectedCityName: selectedCityName,
          selectedDistrictName: selectedDistrictName,
          spiritualPoints: spiritualPoints,
          academicGoal: academicGoal,
          academicGoalDate: moment(baslangicDate).format('DD/MM/YYYY'),
          academicWorkDuration: academicWorkDuration,
          familyTime: familyTime,
          artArea: artArea,
          artDuration: artDuration,
          sportTime: sportTime,
          committeeMemberCount: committeeMemberCount,
          meetingAttendance: meetingAttendance,
          financialContribution: financialContribution,
          activityParticipation: activityParticipation,
          nextActivityPlan: nextActivityPlan
      };
  
      try {
          const snapshot = await get(dbRef);
          if (snapshot.exists()) {
              // Güncelleme işlemi
              await update(dbRef, updatedMissionData);
           
              window.alert("Başarılı: Görev başarıyla güncellendi.");
          } else {
              // Yeni ekleme işlemi
              await set(dbRef, updatedMissionData);
             
              window.alert("Başarılı: Görev başarıyla eklendi.");
          }
          navigate(-1);
      } catch (error) {
          console.error("Veri kaydı sırasında hata: ", error);
          window.alert("Hata: Görev kaydedilirken bir hata oluştu.");
      }
  };
  
  const handleDateChange = (date, type) => {
    if (type === 'baslangic') {
        setBaslangicDate(date);
    } else {
        setBitisDate(date);
    }
};
  
  
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  
    const showDatePicker = () => {
      setDatePickerVisibility(true);
    };
    
    const hideDatePicker = () => {
      setDatePickerVisibility(false);
      console.log("academic goal date: " + academicGoalDate);
    };
    const handleConfirm = (date) => {
        setAcademicGoalDate(date);
        hideDatePicker();
      };


      const formatDate = (date) => {
        return moment(date).format('DD/MM/YYYY'); // Moment.js ile tarih formatlaması
      };
    
  
  
    // Burada kullanıcı arayüzünü ve giriş alanlarını düzenleyeceğiz
    return (
      <div style={styles.scrollView}>
      <div style={styles2.container}>
  
      <p style={styles2.label2}>Görevler</p>
      <div style={styles2.dividermission}/>
  
  <p style={styles2.dateText}>{currentDate}</p>
  
  
        {/* Manevi Gelişim Puanı */}
        <div style={styles2.labelContainer}>
        <MdMenuBook size={iconSize}  />
           
          
        <p style={styles2.label}>Manevi Gelişim Puanı:</p>
      
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setSpiritualPoints(e.target.value)}
          value={spiritualPoints}
          placeholder="Puan giriniz"
          
        />
  <div style={styles2.dividermission2}/>
        {/* Akademik Hedef */}
        <div style={styles2.labelContainer}>
            <MdSchool name="school" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Akademik Hedef:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setAcademicGoal(e.target.value)}
          value={academicGoal}
         
          placeholder="Akademik hedef giriniz"
        />
  <div style={styles2.dividermission2}/>
          {/* Akademik Hedef Tarih */}
        
          <div style={styles2.labelContainer}>
            <MdDateRange name="event" size={iconSize} color={iconColor} />
         <p style={styles2.label}>Akademik Hedef Tarihi:</p>
         </div>
            
         <div style={{ display: 'flex', alignItems: 'center' }}>

         
  
  
 
 
        <div>
        <DatePicker selected={baslangicDate} onChange={(date) => handleDateChange(date, 'baslangic')}  style={styles.datePicker}  />

     
        </div>

</div>
        
  <div style={styles2.dividermission2}/>
        {/* Akademik Gelişim Çalışma Süresi */}
        <div style={styles2.labelContainer}>
            <MdAccessTime name="access-time" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Akademik Çalışma Süresi (dakika):</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setAcademicWorkDuration(e.target.value)}
          value={academicWorkDuration}
          type="number"
          placeholder="Dakika giriniz"
         
        />
  <div style={styles2.dividermission2}/>
        {/* Aile ile Birliktelik */}
        <div style={styles.checkboxContainer}>
  <div style={styles.labelContainer}>
    <MdFamilyRestroom name="family-restroom" size={iconSize} color={iconColor} />
    <p style={styles.label}>Aile ile Birliktelik (15 dk):</p>
  </div>
  
  <label style={styles.labelStyle}>
  <input 
    type="checkbox"
    checked={familyTime === 'Yapıldı'}
    onChange={(e) => toggleFamilyTime(e.target.value)}
    style={{ display: 'none' }} 
  />
   <div style={familyTime === 'Yapıldı' ? { ...styles.customCheckbox, ...styles.checkedCustomCheckbox } : styles.customCheckbox}></div>
  </label>

</div>

        <div style={styles2.dividermission2}/>
        {/* Sanatsal Uğraş Alanı */}
        <div style={styles2.labelContainer}>
            <MdPalette name="palette" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Sanatsal Uğraş Alanı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setArtArea(e.target.value)}
          value={artArea}
       
          placeholder="Sanat alanı giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Sanatsal Çalışma Süresi */}
        <div style={styles2.labelContainer}>
            <MdPalette name="brush" size={iconSize} color={iconColor} />
        <p style={styles.label}>Sanatsal Çalışma Süresi (dakika):</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setArtDuration(e.target.value)}
          value={artDuration}
          type="number"
  
          placeholder="Dakika giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Spor */}
        <div style={styles.checkboxContainer}>
        <div style={styles.labelContainer}>
    <MdSportsSoccer name="fitness-center" size={iconSize} color={iconColor} />
    <p style={styles.label}>Spor (15 dk):</p>
  </div>
        <label style={styles.labelStyle}>
  
  <input
                    type="checkbox"
                    checked={sportTime === 'Yapıldı'}
                    onChange={toggleSportTime}
                    style={{ display: 'none' }}  // Gerçek checkbox gizlenir
                />
                  <div style={sportTime === 'Yapıldı' ? { ...styles.customCheckbox, ...styles.checkedCustomCheckbox } : styles.customCheckbox}></div>
                     </label>
</div>
        <div style={styles2.dividermission2}/>
        {/* Teşkilat Yönetim Üyesi Sayısı */}
        <div style={styles2.labelContainer}>
            <MdPeople name="group" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Teşkilat Yönetim Üyesi Sayısı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setCommitteeMemberCount(e.target.value)}
          value={committeeMemberCount}
          type="number"
   
          placeholder="Üye sayısı giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Haftalık Toplantı Katılımı */}
        
        <div style={styles2.labelContainer}>
            <MdPeople name="meeting-room" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Haftalık Toplantı Katılımı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setMeetingAttendance(e.target.value)}
          value={meetingAttendance}
          type="number"
          placeholder="Katılımcı sayısı giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Mali Çalışma */}
        <div style={styles2.labelContainer}>
            <MdMonetizationOn name="attach-money" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Mali Çalışma: Örn.(₺200 aidat , bağış)</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setFinancialContribution(e.target.value)}
          value={financialContribution}
          type="number"
          placeholder="Para miktarı veya türü giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Gerçekleştirilen Faaliyet ve Katılımcı Sayısı */}
        <div style={styles2.labelContainer}>
            <MdEvent name="event-available" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Gerçekleştirilen Faaliyet ve Katılımcı Sayısı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setActivityParticipation(e.target.value)}
          value={activityParticipation}
            type="number"
          placeholder="Faaliyet ve katılımcı sayısı giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Gelecek Hafta Faaliyet ve Katılımcı Sayısı */}
        <div style={styles2.labelContainer}>
            <MdEvent name="event-note" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Gelecek Hafta Faaliyet ve Planlanan Katılımcı Sayısı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setNextActivityPlan(e.target.value)}
          value={nextActivityPlan}
   type="number"
          placeholder="Planlanan faaliyet ve katılımcı sayısı giriniz"
        />
   <div style={styles2.buttoncontainer}>
      
          <button
            style={styles.missionButton}
            onClick={handleSubmit}
          >
             <p style={styles.missionButtonText}>Kaydet</p>
            <MdSave name="save" size={30} color={'white'} />
          </button>
        </div>
      </div>
      <div style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
        <DatePicker selected={bitisDate} onChange={(date) => handleDateChange(date, 'bitis')} />
      </div>
      </div>
    );
  };



  const desktopStyles = {
    scrollView: {
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: '#DCDCDC',
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        marginRight: 10,
        justifyContent: 'flex-start',
    },
    dividerMission2: {
        borderColor: 'gray',
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 10,
        marginBottom: 10,
        width: '100%',
    },
    switchContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginBottom: 20,
    },
    label: {
        marginBottom: 5,
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        color: 'black',
        marginLeft: 5,
    },
    buttonMission: {
        marginTop: 20,
        width: '80%',
        marginBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'blue',
        borderRadius: 10,
    },
    missionButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 40,
        padding: 10,
    },
    dateText: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 40,
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    customCheckbox: {
        width: '24px',
        height: '24px',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        border: '2px solid #ccc',
        position: 'relative',
        transition: 'background-color 0.3s, border-color 0.3s',
        marginRight: '10px',
    },
    checkedCustomCheckbox: {
        backgroundColor: '#4CAF50',
        border: '2px solid #4CAF50',
    },
    labelStyle: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        width: 'fit-content',
    },
    missionButton: {
        padding: '10px 20px',
        backgroundColor: '#4CAF50',
        color: 'white',
        fontSize: '16px',
        fontWeight: 'bold',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s, transform 0.3s',
        outline: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '10px 0',
        width: '30%',
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    missionButtonText: {
        textAlign: 'center',
        marginRight: 20,
        fontSize: 20,
    },
};

const desktopStyles2 = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        marginTop: 50,
    },
    dividermission: {
        display: 'flex',
        borderColor: 'gray',
        borderStyle: 'solid',
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    },
    dateText: {
        display: 'flex',
        fontSize: 30,
        fontWeight: 'bold',
        marginBottom: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dividermission2: {
        display: 'flex',
        borderColor: 'lightgray',
        borderStyle: 'solid',
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 10,
        marginBottom: 10,
        width: '100%',
    },
    switchContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 20,
    },
    label: {
        marginBottom: 5,
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        color: 'black',
        marginLeft: 5,
    },
    label2: {
        display: 'flex',
        fontSize: 30,
        fontWeight: 'bold',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'center',
    },
    button: {
        marginTop: 20,
        width: '80%',
        marginBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'blue',
        borderRadius: 10,
    },
    scrollView: {
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: '#f5f5f5',
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'flex-start',
    },
    missionButton: {
        padding: '10px 20px',
        backgroundColor: '#4CAF50',
        color: 'white',
        fontSize: '16px',
        fontWeight: 'bold',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s, transform 0.3s',
        outline: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '10px 0',
    },
    missionButtonText: {
        fontSize: 17,
        textAlign: 'center',
    },
    buttoncontainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'center',
    },
};

const mobileStyles = {
  scrollView: {
      padding: 5,
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      backgroundColor: '#DCDCDC',
  },
  labelContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
      marginRight: 5,
      justifyContent: 'flex-start',
  },
  dividerMission2: {
      borderColor: 'gray',
      width: '100%',
      borderWidth: 1,
      marginBottom: 10,
      marginTop: 10,
  },
  input: {
      borderWidth: 1,
      borderColor: '#ddd',
      padding: 5,
      marginBottom: 10,
      width: '100%',
  },
  switchContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginBottom: 15,
  },
  label: {
      marginBottom: 5,
      fontSize: 14,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 5,
      color: 'black',
      marginLeft: 3,
  },
  buttonMission: {
      marginTop: 15,
      width: '90%',
      marginBottom: 15,
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'blue',
      borderRadius: 10,
  },
  missionButtonText: {
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: 35,
      padding: 8,
  },
  dateText: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 30,
  },
  checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
  },
  customCheckbox: {
      width: '20px',
      height: '20px',
      backgroundColor: '#f5f5f5',
      borderRadius: '4px',
      border: '2px solid #ccc',
      position: 'relative',
      transition: 'background-color 0.3s, border-color 0.3s',
      marginRight: '8px',
  },
  checkedCustomCheckbox: {
      backgroundColor: '#4CAF50',
      border: '2px solid #4CAF50',
  },
  labelStyle: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      width: 'fit-content',
  },
  missionButton: {
      padding: '8px 15px',
      backgroundColor: '#4CAF50',
      color: 'white',
      fontSize: '14px',
      fontWeight: 'bold',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s, transform 0.3s',
      outline: 'none',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      margin: '8px 0',
      width: '90%',
      height: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
  },
  missionButtonText: {
      textAlign: 'center',
      marginRight: 15,
      fontSize: 18,
  },
};

const mobileStyles2 = {
  container: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: 15,
      marginTop: 30,
  },
  dividermission: {
      display: 'flex',
      borderColor: 'gray',
      borderStyle: 'solid',
      width: '100%',
      borderWidth: 1,
      marginBottom: 8,
      marginTop: 8,
  },
  dateText: {
      display: 'flex',
      fontSize: 25,
      fontWeight: 'bold',
      marginBottom: 30,
      justifyContent: 'center',
      alignItems: 'center',
  },
  dividermission2: {
      display: 'flex',
      borderColor: 'lightgray',
      borderStyle: 'solid',
      width: '100%',
      borderWidth: 1,
      marginBottom: 8,
      marginTop: 8,
  },
  input: {
      borderWidth: 1,
      borderColor: '#ddd',
      padding: 8,
      marginBottom: 8,
      width: '100%',
  },
  switchContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: 15,
  },
  label: {
      marginBottom: 5,
      fontSize: 14,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 5,
      color: 'black',
      marginLeft: 3,
  },
  label2: {
      display: 'flex',
      fontSize: 25,
      fontWeight: 'bold',
      alignItems: 'center',
      marginBottom: 5,
      justifyContent: 'center',
  },
  button: {
      marginTop: 15,
      width: '90%',
      marginBottom: 15,
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'blue',
      borderRadius: 10,
  },
  scrollView: {
      padding: 8,
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      backgroundColor: '#f5f5f5',
  },
  labelContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
      justifyContent: 'flex-start',
  },
  missionButton: {
      padding: '8px 15px',
      backgroundColor: '#4CAF50',
      color: 'white',
      fontSize: '14px',
      fontWeight: 'bold',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s, transform 0.3s',
      outline: 'none',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      margin: '8px 0',
  },
  missionButtonText: {
      fontSize: 15,
      textAlign: 'center',
  },
  buttoncontainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
      justifyContent: 'center',
  },
};




export default DayMissionScreenEdit;