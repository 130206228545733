

import React, { useState, useEffect } from 'react';
import { useNavigate , useParams, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';

import Datetime from 'react-datetime';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue, get, set} from 'firebase/database';
import moment from 'moment';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker'; // Web için tarih seçici
import { getAnalytics } from 'firebase/analytics';
import {
    MdVisibility, // Göz ikonu, genel görüntüleme için kullanılabilir
    MdEdit, // Düzeltme kalem ikonu, her türlü düzenleme için
    MdSchool, // Akademik hedef ve çalışmalar için okul ikonu
    MdFamilyRestroom, // Aile ile etkinlikler için aile ikonu
    MdPalette, // Sanatsal uğraşlar için palet ikonu
    MdAccessTime, // Zamanla ilgili görevler için saat ikonu
    MdSportsSoccer, // Spor aktiviteleri için futbol topu ikonu
    MdDateRange,
    MdMenuBook,
    MdPeople, // Üye sayısı veya toplantı katılımcıları için insanlar ikonu
    MdMonetizationOn, // Mali işler için para ikonu
    MdEvent, // Planlanmış etkinlikler için etkinlik ikonu
    MdStar,
    MdSave
} from 'react-icons/md';


const DayMissionScreen = ({ navigation }) => {  
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
  
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      const userRef = ref(db, `kullanicilar/${userId}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setUserData(data);
      }, {
        onlyOnce: true
      });
    }
  }, []);
  
  
  
  
 
    const [currentDate, setCurrentDate] = useState('');
    const [day, setDay] = useState('');
    const [spiritualPoints, setSpiritualPoints] = useState('');
    const [academicGoal, setAcademicGoal] = useState('');
    const [academicGoalDate, setAcademicGoalDate] = useState(new Date());

    const [academicWorkDuration, setAcademicWorkDuration] = useState('');
    const [familyTime, setFamilyTime] = useState('Yapılmadı');
  
    const [artArea, setArtArea] = useState('');
    const [artDuration, setArtDuration] = useState('');
    const [sportTime, setSportTime] = useState('Yapılmadı');
    const [committeeMemberCount, setCommitteeMemberCount] = useState('');
    const [meetingAttendance, setMeetingAttendance] = useState('');
    const [financialContribution, setFinancialContribution] = useState('');
    const [activityParticipation, setActivityParticipation] = useState('');
    const [nextActivityPlan, setNextActivityPlan] = useState('');
    const [baslangicDate, setBaslangicDate] = useState(new Date());
    const [bitisDate, setBitisDate] = useState(new Date());
    const [isVisible, setIsVisible] = useState(false);
   // İkon boyutu ve renk tanımlamaları
   const iconSize = 30;
   const iconColor = 'black';
    // Stil tanımlamaları
    const handleDateChange = (date, type) => {
      if (type === 'baslangic') {
          setBaslangicDate(date);
      } else {
          setBitisDate(date);
      }
  };
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = isMobile ? mobileStyles : desktopStyles;
  const styles2 = isMobile ? mobileStyles2 : desktopStyles2;

  
  // Switch'lerin değerlerini güncelleyecek fonksiyonlar
  const toggleFamilyTime = () => {
    setFamilyTime(familyTime === 'Yapıldı' ? 'Yapılmadı' : 'Yapıldı');
  };
  
  const toggleSportTime = () => {
    setSportTime(sportTime === 'Yapıldı' ? 'Yapılmadı' : 'Yapıldı');
  };
    useEffect(() => {
      const today = new Date();
       // Gün adını almak için `toLocaleDateString` ve `options` kullanın.
    const dayName = today.toLocaleDateString('tr-TR', { weekday: 'long' });
    // Gün adını state'e kaydedin.
    console.log(dayName); // Konsolda doğru gün adını görmek için
    setDay(dayName);
      const formattedDate = 
        String(today.getDate()).padStart(2, '0') + '-' +
        String(today.getMonth() + 1).padStart(2, '0') + '-' +
        today.getFullYear(); // Tarih
    
        setCurrentDate(formattedDate); 
      
    }, []);
    
  
    const KademeRadioButton = ({ value, selectedValue, onClick }) => {
        const isSelected = selectedValue === value;
        return (
            <button style={styles.radioButton} onClick={onClick} aria-label={value}>
                <div style={isSelected ? { ...styles.radioCircle, ...styles.selectedRadioCircle } : styles.radioCircle} />
                <label htmlFor={value} style={styles.radioText}>{value}</label>
            </button>
        );
    };
    
      
    
  
  
    const db = getDatabase();
    const dbRef = ref(db, `gorevler/${userId}/${currentDate}`);
  
  
    
    const handleSubmit = () => {
      if (!userData) {
        console.error('Kullanıcı bilgileri yüklenmedi.');
        return;
      }
   
      const { fullName, otherJob, selectedCityName, selectedDistrictName } = userData;
      if (!fullName || !otherJob || !selectedCityName || !selectedDistrictName ) {
        console.error('Kullanıcı bilgileri eksik.');
        return;
      }
      const gorevlerData = {
        currentDate: currentDate,
        dayName: day, // Gün adı
        fullName: fullName,
        otherJob: otherJob,
        selectedCityName: selectedCityName,
        selectedDistrictName: selectedDistrictName,
        spiritualPoints: spiritualPoints,
        academicGoal: academicGoal,
        academicGoalDate: moment(baslangicDate).format('DD/MM/YYYY'),
        academicWorkDuration: academicWorkDuration,
        familyTime: familyTime,
        artArea: artArea,
        artDuration: artDuration,
        sportTime: sportTime,
        committeeMemberCount: committeeMemberCount,
        meetingAttendance: meetingAttendance,
        financialContribution: financialContribution,
        activityParticipation: activityParticipation,
        nextActivityPlan: nextActivityPlan
      };
      set(dbRef, gorevlerData)
      .then(() => {
        console.log("Görevler başarıyla kaydedildi");
        window.alert(
          "Başarılı: Görev başarıyla kaydedildi."
        );
        navigate('/home'); 
      })
      .catch(error => {
        console.error("Veri kaydı sırasında hata: ", error);
        window.alert("Hata: Görev kaydedilirken bir hata oluştu.");
      });
  };
    
  useEffect(() => {
    const userRef = ref(db, 'gorevler/' + userId);
    onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // En yeni tarihli klasörün key'ini al (tarihler YYYYMMDD formatında olmalı)
        const dateKeys = Object.keys(data).sort((a, b) => b - a);
        const lastDateKey = dateKeys[0];
        const lastDateData = data[lastDateKey];
  
        setAcademicGoal(lastDateData.academicGoal || '');
        setAcademicGoalDate(lastDateData.academicGoalDate || '');
        setArtArea(lastDateData.artArea || '');
        setMeetingAttendance(lastDateData.meetingAttendance || ''); // Haftalık toplantı sayısını ayarla
        
        // ... diğer state'leri ayarlayabilirsiniz
      }
    }, {
      onlyOnce: true
    });
  }, []);

  
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };
  
  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };
  
  const handleConfirm = (date) => {
  // `date` parametresini bir Date nesnesine dönüştürün
  const selectedDate = new Date(date.date);
  setAcademicGoalDate(selectedDate);
  hideDatePicker();
  };
  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY'); // Moment.js ile tarih formatlaması
  };
  
  
    // Burada kullanıcı arayüzünü ve giriş alanlarını düzenleyeceğiz
    return (
      <div style={styles.scrollView}>
      <div style={styles2.container}>
  
      <p style={styles2.label2}>Görevler</p>
      <div style={styles2.dividermission}/>
  
  <p style={styles2.dateText}>{currentDate}</p>
  
  
        {/* Manevi Gelişim Puanı */}
        <div style={styles2.labelContainer}>
  
           
            <MdMenuBook name="psychology" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Manevi Gelişim Puanı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setSpiritualPoints(e.target.value)}
          value={spiritualPoints}
         
          placeholder="Puan giriniz"
   
        />
  <div style={styles2.dividermission2}/>
        {/* Akademik Hedef */}
        
        <div style={styles2.labelContainer}>
            <MdSchool name="school" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Akademik Hedef:</p>
        </div>
        <div style={styles.registerInputRow}>
          
          <div style={styles.radioGroup2}>
            <KademeRadioButton 
              value="Yüksek Lisans Hazırlık" 
              selectedValue={academicGoal} 
              onClick={() => setAcademicGoal('Yüksek Lisans Hazırlık')} 
            />
            <KademeRadioButton 
              value="Yüksek Lisans Ders Dönemi" 
              selectedValue={academicGoal} 
              onClick={() => setAcademicGoal('Yüksek Lisans Ders Dönemi')} 
            />
            <KademeRadioButton 
              value="Yüksek Lisans Tez Dönemi" 
              selectedValue={academicGoal} 
              onClick={() => setAcademicGoal('Yüksek Lisans Tez Dönemi')} 
            />
               <KademeRadioButton 
              value="Doktora Hazırlık" 
              selectedValue={academicGoal} 
              onClick={() => setAcademicGoal('Doktora Hazırlık')} 
            />
               <KademeRadioButton 
              value="Doktora Ders Dönemi" 
              selectedValue={academicGoal} 
              onClick={() => setAcademicGoal('Doktora Ders Dönemi')} 
            />
               <KademeRadioButton 
              value="Doktora Tez Dönemi" 
              selectedValue={academicGoal} 
              onClick={() => setAcademicGoal('Doktora Tez Dönemi')} 
            />
  
          </div>
        </div>
  
  
  
      
  <div style={styles2.dividermission2}/>
        {/* Akademik Hedef Tarih */}
        
        <div style={styles2.labelContainer}>
            <MdDateRange name="event" size={iconSize} color={iconColor} />
         <p style={styles2.label}>Akademik Hedef Tarihi:</p>
         </div>
         <div style={{ display: 'flex', alignItems: 'center' }}>

         

         <DatePicker selected={baslangicDate} onChange={(date) => handleDateChange(date, 'baslangic')}  style={styles.datePicker}  />


<div>



           





</div>


</div>
       
  
  
  <div style={styles2.dividermission2}/>
        {/* Akademik Gelişim Çalışma Süresi */}
        <div style={styles2.labelContainer}>
            <MdAccessTime name="access-time" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Akademik Çalışma Süresi (dakika):</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setAcademicWorkDuration(e.target.value)}
          value={academicWorkDuration}
         
          placeholder="Dakika giriniz"
       
        />
  <div style={styles2.dividermission2}/>
        {/* Aile ile Birliktelik */}
        <div style={styles2.switchContainer}>
        <div style={styles2.labelContainer}>
            <MdFamilyRestroom name="family-restroom" size={iconSize} color={iconColor} />
          <p style={styles2.label}>Aile ile Birliktelik (15 dk):</p>
          </div>
          <label style={styles.labelStyle}>
          <input 
  type="checkbox"
  onChange={(event) => toggleFamilyTime(event.target.checked)}
  style={{ display: 'none' }} 
  checked={familyTime === 'Yapıldı'}
/>
<div style={familyTime === 'Yapıldı' ? { ...styles.customCheckbox, ...styles.checkedCustomCheckbox } : styles.customCheckbox}></div>
</label>
        </div>
        <div style={styles2.dividermission2}/>
        {/* Sanatsal Uğraş Alanı */}
        <div style={styles2.labelContainer}>
            <MdPalette name="palette" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Sanatsal Uğraş Alanı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setArtArea(e.target.value)}
          value={artArea}

          placeholder="Sanat alanı giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Sanatsal Çalışma Süresi */}
        <div style={styles2.labelContainer}>
            <MdPalette name="brush" size={iconSize} color={iconColor} />
        <p style={styles.label}>Sanatsal Çalışma Süresi (dakika):</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setArtDuration(e.target.value)}
          value={artDuration}
         
       type="number"
          placeholder="Dakika giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Spor */}
        <div style={styles2.switchContainer}>
        <div style={styles2.labelContainer}>
            <MdSportsSoccer name="fitness-center" size={iconSize} color={iconColor} />
          <p style={styles2.label}>Spor (15 dk):</p>
          </div>
          <label style={styles.labelStyle}>
          <input 
  type="checkbox"
  onChange={(event) => toggleSportTime(event.target.checked)}
  checked={sportTime === 'Yapıldı'}
  style={{ display: 'none' }} 
/>
<div style={sportTime === 'Yapıldı' ? { ...styles.customCheckbox, ...styles.checkedCustomCheckbox } : styles.customCheckbox}></div>
</label>
        </div>
        <div style={styles2.dividermission2}/>
        {/* Teşkilat Yönetim Üyesi Sayısı */}
        <div style={styles2.labelContainer}>
            <MdPeople name="group" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Teşkilat Yönetim Üyesi Sayısı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setCommitteeMemberCount(e.target.value)}
          value={committeeMemberCount}
     type="number"
  
          placeholder="Üye sayısı giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Haftalık Toplantı Katılımı */}
        
        <div style={styles2.labelContainer}>
            <MdPeople name="meeting-room" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Haftalık Toplantı Katılımı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setMeetingAttendance(e.target.value)}
          value={meetingAttendance}
       
         type="number"
          placeholder="Katılımcı sayısı giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Mali Çalışma */}
        <div style={styles2.labelContainer}>
            <MdMonetizationOn name="attach-money" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Mali Çalışma: Örn.(₺200 aidat , bağış)</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setFinancialContribution(e.target.value)}
          value={financialContribution}
          type="number"
          placeholder="Para miktarı veya türü giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Gerçekleştirilen Faaliyet ve Katılımcı Sayısı */}
        <div style={styles2.labelContainer}>
            <MdEvent name="event-available" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Gerçekleştirilen Faaliyet ve Katılımcı Sayısı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setActivityParticipation(e.target.value)}
          value={activityParticipation}
      type="number"
          placeholder="Faaliyet ve katılımcı sayısı giriniz"
        />
  <div style={styles2.dividermission2}/>
        {/* Gelecek Hafta Faaliyet ve Katılımcı Sayısı */}
        <div style={styles2.labelContainer}>
            <MdEvent name="event-note" size={iconSize} color={iconColor} />
        <p style={styles2.label}>Gelecek Hafta Faaliyet ve Planlanan Katılımcı Sayısı:</p>
        </div>
        <input 
          style={styles2.input}
          onChange={(e) => setNextActivityPlan(e.target.value)}
          value={nextActivityPlan}
       type="number"
          placeholder="Planlanan faaliyet ve katılımcı sayısı giriniz"
        />
  <div style={styles2.buttoncontainer}>
      
      <button
        style={styles.missionButton}
        onClick={handleSubmit}
      >
         <p style={styles.missionButtonText}>Gönder</p>
        <MdSave name="save" size={30} color={'white'} />
      </button>
    </div>
      </div>
      <div style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
        <DatePicker selected={bitisDate} onChange={(date) => handleDateChange(date, 'bitis')} />
      </div>
      </div>
    );
  };
  
  const desktopStyles = {
    scrollView: {
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: '#DCDCDC',
        
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        marginRight: 10,
        justifyContent: 'flex-start',
    },
    dividerMission2: {
        borderColor: 'gray',
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 10,
        marginBottom: 10,
        width: '100%',
    },
    switchContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginBottom: 20,
    },
    label: {
        marginBottom: 5,
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        color: 'black',
        marginLeft: 5,
    },
    buttonMission: {
        marginTop: 20,
        width: '80%',
        marginBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'blue',
        borderRadius: 10,
    },
    missionButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 40,
        padding: 10,
    },
    dateText: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 40,
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    customCheckbox: {
        width: '24px',
        height: '24px',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        border: '2px solid #ccc',
        position: 'relative',
        transition: 'background-color 0.3s, border-color 0.3s',
        marginRight: '10px', // Checkbox ile metin arasındaki mesafe
    },
    checkedCustomCheckbox: {
        backgroundColor: '#4CAF50',
        border: '2px solid #4CAF50',
    },
    labelStyle: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse', // Metni checkbox'ın sağ tarafına al
        width: 'fit-content', // Yalnızca içeriği kadar yer kaplar
    },
    missionButton: {
        padding: '10px 20px', // Butonun boyutunu ayarlar
        backgroundColor: '#4CAF50', // Yeşil arka plan rengi
        color: 'white', // Metin rengi
        fontSize: '16px', // Metin boyutu
        fontWeight: 'bold', // Metin kalınlığı
        borderRadius: '5px', // Kenar yuvarlaklığı
        border: 'none', // Kenar çizgisini kaldırır
        cursor: 'pointer', // Fare imlecini işaretçiye dönüştürür
        transition: 'background-color 0.3s, transform 0.3s', // Renk değişimi ve dönüşüm animasyonu
        outline: 'none', // Odaklanma çizgisini kaldırır
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Gölgelendirme efekti
        margin: '10px 0', // Üst ve alttan boşluk,
        width: '30%',
        height: 60,
        display: 'flex', // Flexbox kullanıyoruz
        justifyContent: 'center', // İçerikleri merkezde hizalar
        alignItems: 'center',
        display: 'flex',
    },
    missionButtonText: {
        textAlign: 'center', // Metin hizalaması
        marginRight: 20,
        fontSize: 20,
    },
    radioButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px',
        margin: '5px',
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    radioCircle: {
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        border: '2px solid #ddd',
        marginRight: '10px',
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        background: '#fff', // Varsayılan arka plan rengi
    },
    selectedRadioCircle: {
        background: '#4CAF50', // Seçili olduğunda arka plan rengi yeşil
        border: '2px solid #4CAF50', // Seçili olduğunda kenarlık rengi
    },
    radioText: {
        fontSize: '16px',
    },
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        marginTop: 50,
    },
    dividermission: {
        display: 'flex',
        borderColor: 'gray',
        borderStyle: 'solid',  // Border stilini ekleyin
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    },
    dateText: {
        display: 'flex',
        fontSize: 30,
        fontWeight: 'bold',
        marginBottom: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dividermission2: {
        display: 'flex',
        borderColor: 'lightgray',
        borderStyle: 'solid',  // Border stilini ekleyin
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 10,
        marginBottom: 10,
        width: '100%',
    },
    switchContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 20,
    },
    label: {
        marginBottom: 5,
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        color: 'black',
        marginLeft: 5,
    },
    label2: {
        display: 'flex',
        fontSize: 30,
        fontWeight: 'bold',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'center',
    },
    button: {
        marginTop: 20,
        width: '80%',
        marginBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'blue',
        borderRadius: 10,
    },
    scrollView: {
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: '#f5f5f5',
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'flex-start',
    },
    missionButton: {
        padding: '10px 20px', // Butonun boyutunu ayarlar
        backgroundColor: '#4CAF50', // Yeşil arka plan rengi
        color: 'white', // Metin rengi
        fontSize: '16px', // Metin boyutu
        fontWeight: 'bold', // Metin kalınlığı
        borderRadius: '5px', // Kenar yuvarlaklığı
        border: 'none', // Kenar çizgisini kaldırır
        cursor: 'pointer', // Fare imlecini işaretçiye dönüştürür
        transition: 'background-color 0.3s, transform 0.3s', // Renk değişimi ve dönüşüm animasyonu
        outline: 'none', // Odaklanma çizgisini kaldırır
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Gölgelendirme efekti
        margin: '10px 0', // Üst ve alttan boşluk
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    missionButtonText: {
        fontSize: 17,
        textAlign: 'center', // Metin hizalaması
        marginRight: '10px'
    },
    buttoncontainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'center',
    },
};

  
  const desktopStyles2 = {
    container: {
      
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
      marginTop: 50,
    },
    dividermission: {
        display: 'flex',
        borderColor: 'gray',
        borderStyle: 'solid',  // Border stilini ekleyin
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    
    },
    dateText: {
        display: 'flex',
      fontSize: 30,
      fontWeight: 'bold',
      marginBottom: 40,
      fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center'
      // Diğer stil özellikleri...
    },
    dividermission2: {
        display: 'flex',
        borderColor: 'lightgray',
        borderStyle: 'solid',  // Border stilini ekleyin
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    
    },
    
    
    input: {
      borderWidth: 1,
      borderColor: '#ddd',
      padding: 10,
      marginBottom: 10,
      width: '100%',
    },
    switchContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: 20,
    },
    label: {
      marginBottom: 5,
      fontSize: 16,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10,
      color: 'black',
      marginLeft: 5,
    },
    label2: {
        display: 'flex',
      fontSize: 30,
      fontWeight: 'bold',
      alignItems: 'center',
      marginBottom:5,
      justifyContent: 'center',
   
    },
    button: {
      marginTop: 20,
      width: '80%',
      marginBottom: 20,
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'blue',
      borderRadius: 10,
    },
    scrollView: {
      padding:10,
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      backgroundColor: '#f5f5f5',
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'flex-start'
      },
      missionButton: {
        padding: '10px 20px', // Butonun boyutunu ayarlar
        backgroundColor: '#4CAF50', // Yeşil arka plan rengi
        color: 'white', // Metin rengi
        fontSize: '16px', // Metin boyutu
        fontWeight: 'bold', // Metin kalınlığı
        borderRadius: '5px', // Kenar yuvarlaklığı
        border: 'none', // Kenar çizgisini kaldırır
        cursor: 'pointer', // Fare imlecini işaretçiye dönüştürür
        transition: 'background-color 0.3s, transform 0.3s', // Renk değişimi ve dönüşüm animasyonu
        outline: 'none', // Odaklanma çizgisini kaldırır
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Gölgelendirme efekti
        margin: '10px 0', // Üst ve alttan boşluk
     
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    missionButtonText: {
        fontSize: 17,
        textAlign: 'center', // Metin hizalaması
        marginLeft: '10px'
      },
    buttoncontainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'center',
     
    }
  };
  const mobileStyles = {
    scrollView: {
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: '#DCDCDC',
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        marginRight: 5,
        justifyContent: 'flex-start',
    },
    dividerMission2: {
        borderColor: 'gray',
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 5,
        marginBottom: 10,
        width: '100%',
    },
    switchContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginBottom: 15,
    },
    label: {
        marginBottom: 5,
        fontSize: 14,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
        color: 'black',
        marginLeft: 3,
    },
    buttonMission: {
        marginTop: 15,
        width: '90%',
        marginBottom: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'blue',
        borderRadius: 10,
    },
    missionButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 30,
        padding: 5,
    },
    dateText: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    customCheckbox: {
        width: '20px',
        height: '20px',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        border: '2px solid #ccc',
        position: 'relative',
        transition: 'background-color 0.3s, border-color 0.3s',
        marginRight: '5px', // Checkbox ile metin arasındaki mesafe
    },
    checkedCustomCheckbox: {
        backgroundColor: '#4CAF50',
        border: '2px solid #4CAF50',
    },
    labelStyle: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse', // Metni checkbox'ın sağ tarafına al
        width: 'fit-content', // Yalnızca içeriği kadar yer kaplar
    },
    missionButton: {
        padding: '10px 20px', // Butonun boyutunu ayarlar
        backgroundColor: '#4CAF50', // Yeşil arka plan rengi
        color: 'white', // Metin rengi
        fontSize: '14px', // Metin boyutu
        fontWeight: 'bold', // Metin kalınlığı
        borderRadius: '5px', // Kenar yuvarlaklığı
        border: 'none', // Kenar çizgisini kaldırır
        cursor: 'pointer', // Fare imlecini işaretçiye dönüştürür
        transition: 'background-color 0.3s, transform 0.3s', // Renk değişimi ve dönüşüm animasyonu
        outline: 'none', // Odaklanma çizgisini kaldırır
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Gölgelendirme efekti
        margin: '5px 0', // Üst ve alttan boşluk,
        width: '80%',
        height: 50,
        display: 'flex', // Flexbox kullanıyoruz
        justifyContent: 'center', // İçerikleri merkezde hizalar
        alignItems: 'center',
    },
    missionButtonText: {
        textAlign: 'center', // Metin hizalaması
        marginRight: 10,
        fontSize: 18,
    },
    radioButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px',
        margin: '3px',
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    radioCircle: {
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        border: '2px solid #ddd',
        marginRight: '5px',
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        background: '#fff', // Varsayılan arka plan rengi
    },
    selectedRadioCircle: {
        background: '#4CAF50', // Seçili olduğunda arka plan rengi yeşil
        border: '2px solid #4CAF50', // Seçili olduğunda kenarlık rengi
    },
    radioText: {
        fontSize: '14px',
    },
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        marginTop: 40,
    },
    dividermission: {
        display: 'flex',
        borderColor: 'gray',
        borderStyle: 'solid',  // Border stilini ekleyin
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    },
    dateText: {
        display: 'flex',
        fontSize: 25,
        fontWeight: 'bold',
        marginBottom: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dividermission2: {
        display: 'flex',
        borderColor: 'lightgray',
        borderStyle: 'solid',  // Border stilini ekleyin
        width: '100%',
        borderWidth: 1,
        marginBottom: 10,
        marginTop: 10,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 5,
        marginBottom: 10,
        width: '100%',
    },
    switchContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 15,
    },
    label: {
        marginBottom: 5,
        fontSize: 14,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
        color: 'black',
        marginLeft: 3,
    },
    label2: {
        display: 'flex',
        fontSize: 25,
        fontWeight: 'bold',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'center',
    },
    button: {
        marginTop: 15,
        width: '90%',
        marginBottom: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'blue',
        borderRadius: 10,
    },
    scrollView: {
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: '#f5f5f5',
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'flex-start',
    },
    missionButton: {
        padding: '10px 20px', // Butonun boyutunu ayarlar
        backgroundColor: '#4CAF50', // Yeşil arka plan rengi
        color: 'white', // Metin rengi
        fontSize: '14px', // Metin boyutu
        fontWeight: 'bold', // Metin kalınlığı
        borderRadius: '5px', // Kenar yuvarlaklığı
        border: 'none', // Kenar çizgisini kaldırır
        cursor: 'pointer', // Fare imlecini işaretçiye dönüştürür
        transition: 'background-color 0.3s, transform 0.3s', // Renk değişimi ve dönüşüm animasyonu
        outline: 'none', // Odaklanma çizgisini kaldırır
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Gölgelendirme efekti
        margin: '5px 0', // Üst ve alttan boşluk
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    missionButtonText: {
        fontSize: 15,
        textAlign: 'center', // Metin hizalaması
        marginLeft: '10px'
    },
    buttoncontainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'center',
    },
};

const mobileStyles2 = {
  container: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: 15,
      marginTop: 40,
  },
  dividermission: {
      display: 'flex',
      borderColor: 'gray',
      borderStyle: 'solid',
      width: '100%',
      borderWidth: 1,
      marginBottom: 10,
      marginTop: 10,
  },
  dateText: {
      display: 'flex',
      fontSize: 25,
      fontWeight: 'bold',
      marginBottom: 30,
      justifyContent: 'center',
      alignItems: 'center',
  },
  dividermission2: {
      display: 'flex',
      borderColor: 'lightgray',
      borderStyle: 'solid',
      width: '100%',
      borderWidth: 1,
      marginBottom: 10,
      marginTop: 10,
  },
  input: {
      borderWidth: 1,
      borderColor: '#ddd',
      padding: 5,
      marginBottom: 10,
      width: '100%',
  },
  switchContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: 15,
  },
  label: {
      marginBottom: 5,
      fontSize: 14,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 5,
      color: 'black',
      marginLeft: 3,
  },
  label2: {
      display: 'flex',
      fontSize: 25,
      fontWeight: 'bold',
      alignItems: 'center',
      marginBottom: 5,
      justifyContent: 'center',
  },
  button: {
      marginTop: 15,
      width: '90%',
      marginBottom: 15,
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'blue',
      borderRadius: 10,
  },
  scrollView: {
      padding: 5,
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      backgroundColor: '#f5f5f5',
  },
  labelContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
      justifyContent: 'flex-start',
  },
  missionButton: {
      padding: '8px 15px',
      backgroundColor: '#4CAF50',
      color: 'white',
      fontSize: '14px',
      fontWeight: 'bold',
      borderRadius: '5px',
      border: 'none',
      display: 'flex',
      cursor: 'pointer',
      transition: 'background-color 0.3s, transform 0.3s',
      outline: 'none',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      margin: '5px 0',
  },
  missionButtonText: {
      fontSize: 15,
      textAlign: 'center',
  },
  buttoncontainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
      justifyContent: 'center',
  },
};


export default DayMissionScreen;