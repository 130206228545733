import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set, remove } from "firebase/database";

// Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyDdugV4yuPfYBfVdTs6xEEFhrB6H9XgvRA",
  authDomain: "dernek-9b26c.firebaseapp.com",
  databaseURL: "https://dernek-9b26c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dernek-9b26c",
  storageBucket: "dernek-9b26c.appspot.com",
  messagingSenderId: "58054737120",
  appId: "1:58054737120:web:c4535a79d85d6a63102485",
  measurementId: "G-N3BL5L2NZN",
};

// Firebase'i başlat
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const KomisyonManager = () => {
  const [komisyonlar, setKomisyonlar] = useState({});
  const [newKomisyon, setNewKomisyon] = useState("");
  const [altBaslik, setAltBaslik] = useState({});

  // Firebase'den komisyon verilerini çek
  useEffect(() => {
    const dbRef = ref(database, "komisyonlar");
    const unsubscribe = onValue(dbRef, (snapshot) => {
      const data = snapshot.val() || {};
      setKomisyonlar(data);
    });

    return () => unsubscribe();
  }, []);

  // Yeni bir komisyon ekle
  const addKomisyon = async () => {
    if (!newKomisyon.trim()) {
      alert("Lütfen bir komisyon adı girin!");
      return;
    }

    if (komisyonlar[newKomisyon.trim()]) {
      alert("Bu komisyon zaten mevcut!");
      return;
    }

    if (window.confirm("Bu komisyonu eklemek istediğinize emin misiniz?")) {
      const komisyonKey = newKomisyon.trim();

      try {
        const dbRef = ref(database, `komisyonlar/${komisyonKey}`);
        await set(dbRef, { birimler: {}, komisyon: komisyonKey });
        setNewKomisyon("");
      } catch (error) {
        console.error("Komisyon eklenirken bir hata oluştu:", error);
        alert("Komisyon eklenirken bir hata oluştu. Lütfen tekrar deneyin.");
      }
    }
  };

  // Alt başlık ekle
  const addAltBaslik = async (komisyonKey) => {
    if (!altBaslik[komisyonKey]?.trim()) {
      alert("Lütfen alt başlık adı girin!");
      return;
    }

    if (
      window.confirm(
        `Bu alt başlığı "${komisyonKey}" komisyonuna eklemek istediğinize emin misiniz?`
      )
    ) {
      const yeniAltBaslik = altBaslik[komisyonKey].trim();

      try {
        const dbRef = ref(database, `komisyonlar/${komisyonKey}/birimler`);
        const mevcutAltBasliklar = [
          ...(komisyonlar[komisyonKey]?.birimler || []),
        ];
        mevcutAltBasliklar.push(yeniAltBaslik);
        await set(dbRef, mevcutAltBasliklar);
        setAltBaslik({ ...altBaslik, [komisyonKey]: "" });
      } catch (error) {
        console.error("Alt başlık eklenirken bir hata oluştu:", error);
      }
    }
  };

  // Komisyonu sil
  const deleteKomisyon = async (komisyonKey) => {
    if (window.confirm("Bu komisyonu silmek istediğinize emin misiniz?")) {
      try {
        const dbRef = ref(database, `komisyonlar/${komisyonKey}`);
        await remove(dbRef);
      } catch (error) {
        console.error("Komisyon silinirken bir hata oluştu:", error);
      }
    }
  };

  // Alt başlığı sil
  const deleteAltBaslik = async (komisyonKey, altBaslikIndex) => {
    if (window.confirm("Bu alt başlığı silmek istediğinize emin misiniz?")) {
      try {
        const mevcutAltBasliklar = [...komisyonlar[komisyonKey]?.birimler || []];
        mevcutAltBasliklar.splice(altBaslikIndex, 1);
        const dbRef = ref(database, `komisyonlar/${komisyonKey}/birimler`);
        await set(dbRef, mevcutAltBasliklar);
      } catch (error) {
        console.error("Alt başlık silinirken bir hata oluştu:", error);
      }
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Komisyon Yönetim Sistemi</h1>
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={newKomisyon}
          onChange={(e) => setNewKomisyon(e.target.value)}
          placeholder="Yeni Komisyon Adı"
          style={styles.input}
        />
        <button onClick={addKomisyon} style={styles.addButton}>
          Komisyon Ekle
        </button>
      </div>
      {Object.keys(komisyonlar).length === 0 ? (
        <p style={styles.emptyMessage}>Henüz bir komisyon eklenmedi.</p>
      ) : (
        Object.keys(komisyonlar).map((key) => (
          <div key={key} style={styles.komisyonCard}>
            <h2 style={styles.komisyonTitle}>{key}</h2>
            <button
              onClick={() => deleteKomisyon(key)}
              style={styles.deleteButton}
            >
              Komisyonu Sil
            </button>
            <h3 style={styles.subTitle}>Alt Başlıklar:</h3>
            {komisyonlar[key]?.birimler?.length > 0 ? (
              komisyonlar[key].birimler.map((alt, index) => (
                <div key={index} style={styles.altBaslikRow}>
                  <span style={styles.altBaslikText}>{alt}</span>
                  <button
                    onClick={() => deleteAltBaslik(key, index)}
                    style={styles.deleteButtonSmall}
                  >
                    Sil
                  </button>
                </div>
              ))
            ) : (
              <p style={styles.emptyMessage}>Henüz alt başlık eklenmedi.</p>
            )}
            <div style={styles.inputContainer}>
              <input
                type="text"
                value={altBaslik[key] || ""}
                onChange={(e) =>
                  setAltBaslik({ ...altBaslik, [key]: e.target.value })
                }
                placeholder="Yeni Alt Başlık"
                style={styles.input}
              />
              <button
                onClick={() => addAltBaslik(key)}
                style={styles.addButton}
              >
                Alt Başlık Ekle
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

// Stil objesi
const styles = {
  container: {
    padding: "20px",
    fontFamily: "'Roboto', sans-serif",
    backgroundColor: "#f8f9fa",
    maxWidth: "800px",
    margin: "0 auto",
  },
  title: {
    textAlign: "center",
    color: "#212529",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    width: "300px",
    border: "1px solid #ced4da",
    borderRadius: "5px",
    marginRight: "10px",
  },
  addButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#28a745",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  komisyonCard: {
    border: "1px solid #ced4da",
    borderRadius: "5px",
    padding: "20px",
    marginBottom: "20px",
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  komisyonTitle: {
    margin: "0 0 10px",
    color: "#495057",
  },
  subTitle: {
    margin: "10px 0",
    color: "#6c757d",
  },
  altBaslikRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0",
  },
  altBaslikText: {
    color: "#495057",
  },
  deleteButton: {
    backgroundColor: "#dc3545",
    color: "white",
    border: "none",
    padding: "8px 16px",
    borderRadius: "5px",
    cursor: "pointer",
    marginBottom: "10px",
  },
  deleteButtonSmall: {
    backgroundColor: "#dc3545",
    color: "white",
    border: "none",
    padding: "5px 10px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  emptyMessage: {
    textAlign: "center",
    color: "#6c757d",
  },
   // Mobil uyumluluk eklemek için medya sorguları
   "@media (max-width: 768px)": {
    inputContainer: {
      flexDirection: "column",
      alignItems: "center",
    },
    input: {
      width: "90%", // Mobil cihazlar için genişlik
      marginBottom: "10px", // Dikey boşluk ekleyin
    },
    addButton: {
      width: "90%", // Buton genişliği mobil cihazlara göre uyarlanır
    },
    komisyonCard: {
      padding: "15px", // Daha az padding
    },
    deleteButton: {
      padding: "10px 12px",
      fontSize: "14px", // Daha küçük font boyutu
    },
    deleteButtonSmall: {
      padding: "8px 10px",
      fontSize: "12px", // Daha küçük buton
    },
  },
};

export default KomisyonManager;
