import React, { useState, useEffect } from 'react';
import { getDatabase, ref, push, remove, onValue, get } from 'firebase/database';

const db = getDatabase();

const GuidelinesScreen = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [hasPermission, setHasPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Yüklenme durumunu takip eder

  // Kullanıcının erişim yetkisini kontrol et
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      setMessage('Kullanıcı ID bulunamadı!');
      setIsLoading(false);
      return;
    }

    const userRef = ref(db, `kullanicilar/${userId}`);
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      if (userData && (userData.IKonay && userData.IKyonetim && userData.ik)) {
        setHasPermission(true);
      } else {
        setHasPermission(false);
      }
    });
  }, []);

  // Firebase'den mevcut dosyaları al
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const filesRef = ref(db, 'uploadedFiles');
        const snapshot = await get(filesRef); // Daha hızlı tek seferlik okuma
        const data = snapshot.val();
        if (data) {
          const filesList = Object.keys(data).map(key => ({
            id: key,
            ...data[key],
          }));
          setUploadedFiles(filesList);
        }
      } catch (error) {
        setMessage(`Dosyalar alınırken hata oluştu: ${error.message}`);
      } finally {
        setIsLoading(false); // Yükleme tamamlandı
      }
    };

    fetchFiles();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const splitBase64IntoChunks = (base64, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < base64.length; i += chunkSize) {
      chunks.push(base64.substring(i, i + chunkSize));
    }
    return chunks;
  };

  const uploadFile = () => {
    if (!selectedFile) {
      setMessage('Lütfen bir dosya seçin!');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result.split(',')[1];
      const chunks = splitBase64IntoChunks(base64String, 5000000);

      const fileDatabaseRef = ref(db, 'uploadedFiles');
      const fileId = Date.now().toString();

      try {
        for (let i = 0; i < chunks.length; i++) {
          await push(fileDatabaseRef, {
            fileId,
            name: selectedFile.name,
            chunk: i + 1,
            totalChunks: chunks.length,
            data: chunks[i],
          });
          setProgress(Math.round(((i + 1) / chunks.length) * 100));
        }

        setMessage('Dosya başarıyla yüklendi!');
        setSelectedFile(null);
        setProgress(0);
        // Yeni dosya listeyi güncelle
        setUploadedFiles(prevFiles => [
          ...prevFiles,
          { fileId, name: selectedFile.name, chunk: 1, totalChunks: chunks.length },
        ]);
      } catch (error) {
        setMessage(`Dosya yükleme hatası: ${error.message}`);
      }
    };
    reader.readAsDataURL(selectedFile);
  };

  const deleteFile = async (fileId) => {
    const confirmDelete = window.confirm('Bu dosyayı silmek istediğinize emin misiniz?');
    if (!confirmDelete) return;

    try {
      const filesRef = ref(db, `uploadedFiles`);
      onValue(filesRef, (snapshot) => {
        const files = snapshot.val();

        if (files && typeof files === 'object') {
          Object.keys(files).forEach((key) => {
            if (files[key].fileId === fileId) {
              remove(ref(db, `uploadedFiles/${key}`))
                .then(() => {
                  setMessage('Dosya başarıyla silindi!');
                  setUploadedFiles(prevFiles => prevFiles.filter(file => file.fileId !== fileId));
                })
                .catch((error) => {
                  setMessage(`Dosya silme hatası: ${error.message}`);
                });
            }
          });
        } else {
          setMessage('Silinecek dosya bulunamadı!');
        }
      }, { onlyOnce: true });
    } catch (error) {
      setMessage(`Dosya silme hatası: ${error.message}`);
    }
  };

  const downloadFile = (fileId) => {
    try {
      const chunks = uploadedFiles
        .filter(file => file.fileId === fileId)
        .sort((a, b) => a.chunk - b.chunk)
        .map(file => file.data);

      const base64String = chunks.join('');
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${base64String}`;
      link.download = uploadedFiles.find(file => file.fileId === fileId)?.name || 'download.pdf';
      link.click();

      setMessage('Dosya başarıyla indirildi!');
    } catch (error) {
      setMessage(`Dosya indirme hatası: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Yönergeler Yönetimi</h1>

      {message && <p style={styles.message}>{message}</p>}
      {progress > 0 && <p>Yükleniyor... %{progress}</p>}

      {isLoading ? (
        <p>Yükleniyor...</p> // Yüklenme işareti
      ) : (
        <>
          {hasPermission && (
            <div style={styles.uploadSection}>
              <input type="file" accept=".pdf" onChange={handleFileChange} style={styles.fileInput} />
              <button onClick={uploadFile} style={styles.uploadButton}>Yükle</button>
            </div>
          )}

          <h2 style={styles.subTitle}>Yüklenen Dosyalar</h2>

          <div style={styles.fileList}>
            {uploadedFiles.length > 0 ? (
              uploadedFiles
                .filter((file, index, self) =>
                  index === self.findIndex(f => f.fileId === file.fileId)
                )
                .map(file => (
                  <div key={file.id} style={styles.fileItem}>
                    <span style={styles.fileName}>{file.name}</span>
                    <button onClick={() => downloadFile(file.fileId)} style={styles.downloadButton}>
                      Yükle
                    </button>
                    {hasPermission && (
                      <button onClick={() => deleteFile(file.fileId)} style={styles.deleteButton}>
                        Sil
                      </button>
                    )}
                  </div>
                ))
            ) : (
              <p style={styles.noFilesText}>Henüz dosya yüklenmedi.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  container: { padding: '20px', fontFamily: 'Arial, sans-serif', textAlign: 'center' },
  title: { fontSize: '24px', marginBottom: '20px', color: '#333' },
  message: { fontSize: '16px', color: '#007bff', marginBottom: '20px' },
  uploadSection: { marginBottom: '30px' },
  fileInput: { fontSize: '16px', marginRight: '10px' },
  uploadButton: { padding: '10px 20px', fontSize: '16px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' },
  subTitle: { fontSize: '20px', marginBottom: '15px', color: '#555' },
  fileList: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  fileItem: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', maxWidth: '600px', padding: '10px', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' },
  fileName: { flex: 1, textAlign: 'left', fontSize: '16px', color: '#333' },
  downloadButton: { padding: '5px 10px', fontSize: '14px', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', marginRight: '10px' },
  deleteButton: { padding: '5px 10px', fontSize: '14px', backgroundColor: '#dc3545', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' },
  noFilesText: { fontSize: '16px', color: '#777' },
};

export default GuidelinesScreen;
