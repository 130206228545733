import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LoginScreen from './screens/LoginScreen';
import HomeScreen from './screens/HomeScreen';
import SeeDuyuruScreen from './screens/SeeDuyuruScreen';
import ProfileScreen from './screens/ProfileScreen';
import PersonalDevelopmentScreen from './screens/PersonalDevelopmentScreen';
import RegisterScreen from './screens/RegisterScreen';
import PastMissionScreen from './screens/PastMissionScreen';
import DayMissionScreenEdit from './screens/DayMissionScreenEdit';
import DayMissionScreen from './screens/DayMissionScreen';
import MissionSystemScreen3 from './screens/MissionSystemScreen3';
import TaskManagementScreen from './screens/TaskManagementScreen';
import AllUsersScreen from './screens/AllUsersScreen';
import CreateDuyuruScreen from './screens/CreateDuyuruScreen';
import IKConfirmationScreen from './screens/IKConfirmationScreen';
import IKConfirmationUsersScreen from './screens/IKConfirmationUsersScreen';
import IKRedUsersScreen from './screens/IKRedUsersScreen';
import IKGorevDegisiklikOnayScreen from './screens/IKGorevDegisiklikOnayScreen';
import IKGorevDegisiklikOnaylananlarScreen from './screens/IKGorevDegisiklikOnaylananlarScreen';
import IKGorevDegisiklikReddedilenlerScreen from './screens/IKGorevDegisiklikReddedilenlerScreen';
import TeskilatDegisiklikBildirScreen from './screens/TeskilatDegisiklikBildirScreen';
import TeskilatDegisiklikScreen from './screens/TeskilatDegisiklikScreen';
import ReportScreen from './screens/ReportScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import InsanKaynaklariScreen from './screens/InsanKaynaklariScreen';
import InsanKaynaklariRaporScreen from './screens/InsanKaynaklariRaporScreen';
import KisiselGelisimScreen from './screens/KisiselGelisimScreen';
import MissionSystemScreen2 from './screens/MissionSystemScreen2';
import GuidelinesScreen from './screens/GuidelinesScreen';
import KomisyonManager from './screens/KomisyonManager';
import Footer from './screens/Footer';

const AppContent = () => {
  const location = useLocation();
  const noFooterRoutes = ['/login', '/register', '/forgot-password'];

  return (
    <>
      <div style={{ paddingBottom: '150px' }}>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/seeduyuru" element={<SeeDuyuruScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/personaldevelopment" element={<PersonalDevelopmentScreen />} />
          <Route path="/pastmission" element={<PastMissionScreen />} />
          <Route path="/daymissionedit" element={<DayMissionScreenEdit />} />
          <Route path="/daymission" element={<DayMissionScreen />} />
          <Route path="/missionsystem3" element={<MissionSystemScreen3 />} />
          <Route path="/taskmanagement" element={<TaskManagementScreen />} />
          <Route path="/allusers" element={<AllUsersScreen />} />
          <Route path="/createduyuru" element={<CreateDuyuruScreen />} />
          <Route path="/ikconfirmationusers" element={<IKConfirmationUsersScreen />} />
          <Route path="/ikconfirmation" element={<IKConfirmationScreen />} />
          <Route path="/ikredusers" element={<IKRedUsersScreen />} />
          <Route path="/ikgorevdegisiklikonaylananlar" element={<IKGorevDegisiklikOnaylananlarScreen />} />
          <Route path="/ikgorevdegisiklik" element={<IKGorevDegisiklikOnayScreen />} />
          <Route path="/ikdgorevdegisiklikreddedilenler" element={<IKGorevDegisiklikReddedilenlerScreen />} />
          <Route path="/teskilatdegisik" element={<TeskilatDegisiklikBildirScreen />} />
          <Route path="/teskilat" element={<TeskilatDegisiklikScreen />} />
          <Route path="/report" element={<ReportScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/insankaynaklari" element={<InsanKaynaklariScreen />} />
          <Route path="/insankaynaklarirapor" element={<InsanKaynaklariRaporScreen />} />
          <Route path="/kisiselgelisim" element={<KisiselGelisimScreen />} />
          <Route path="/missionsystem2" element={<MissionSystemScreen2 />} />
          <Route path="/guidelines" element={<GuidelinesScreen />} />
          <Route path="/komisyonmanager" element={<KomisyonManager />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
      {!noFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
